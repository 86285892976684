<template>
  <div>
    <a-row>
      <div class="title custom-flex">
        <div class="icon" />
        <span>租户数量统计</span>
      </div>
    </a-row>
    <a-row>
      <div class="tenant-count custom-flex custom-items-center">
        <div>
          <a-icon type="team" :style="{fontSize:'60px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>有效租户总数</div>
          <div class="count">{{ totalTenantNumber }}</div>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import { findSystemTaskStatistics } from '@/api/home'
export default {
  name: 'SystemTenantNumberStatistics',
  data() {
    return {
      totalTenantNumber: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findSystemTaskStatistics().then(res => {
        if (res.code === 0) {
          this.totalTenantNumber = res.data.total_number_of_tenant
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}
.tenant-count{
  border-radius: 10px;
  margin-top: 22px;
  background: linear-gradient(to top right,#00acff, #0082fe);
  padding: 20px 0 20px 50px;
}
.content {
  font-size: 20px;
  color: #ffffff;
  margin-left: 20px;

  .count {
    font-size: 40px;
  }
}

</style>

